import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  GENERATE_MAP,
  CREATE_ROOT_PROBLEM,
  GET_ALL_PROBLEMS,
} from "../../Graphql/User/Query";
import Checkbox from "@mui/material/Checkbox";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import Sidebar from "./SideBar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";

import StyledTooltip from "./StyledTooltip";

const CustomCheckbox = styled(Checkbox)({
  color: "#65558f",
  "&.Mui-checked": {
    color: "#65558f",
  },
});

const DemandIndex = () => {
  const location = useLocation();
  const { problemStatement, demand, challenge } = location.state;
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false); // For map generation
  const [isModalOpen, setModalOpen] = useState(false); // For NO-GO modal
  const [scores, setScores] = useState({
    ExecutiveSupport: demand.ExecutiveSupport || "",
    ServiceLineAdministrativeEngagement:
      demand.ServiceLineAdministrativeEngagement || "",
    ClinicianEngagement: demand.ClinicianEngagement || "",
    StrengthOfSupportingEvidence: demand.StrengthOfSupportingEvidence || "",
    ImpactToTheQuadrupleAim: demand.ImpactToTheQuadrupleAim || "",
    PotentialToScaleUp: demand.PotentialToScaleUp || "",
  });

  const [notApplicable, setNotApplicable] = useState({
    ExecutiveSupport: false,
    ServiceLineAdministrativeEngagement: false,
    ClinicianEngagement: false,
    StrengthOfSupportingEvidence: false,
    ImpactToTheQuadrupleAim: false,
    PotentialToScaleUp: false,
  });

  const [AdjustedScore, setAdjustedScore] = useState(demand.AdjustedScore || 0);
  const [Grade, setGrade] = useState(demand.Grade || "");
  const [status, setStatus] = useState("");

  const weightFactors = {
    ExecutiveSupport: 5,
    ServiceLineAdministrativeEngagement: 3,
    ClinicianEngagement: 5,
    StrengthOfSupportingEvidence: 1,
    ImpactToTheQuadrupleAim: 1,
    PotentialToScaleUp: 3,
  };

  const [generateMap] = useMutation(GENERATE_MAP, {
    onCompleted: async (datamap) => {
      try {
        const rootProblemData = {
          ...datamap.generateMap,
          demand: demand,
          id: uuidv4(),
        };

        const done_data = await createRootProblem({
          variables: {
            userId: localStorage.getItem("userId"),
            data: rootProblemData,
          },
        });

        showToast("Problem Created", "success");
        setDialogOpen(false); // Close the Dialog after completion
        setTimeout(() => {
          navigate("/nudgebuilder/custombuilder", {
            state: { problemId: done_data.data.createRootProblem.id },
          });
        }, 1500);
      } catch (error) {
        console.error("Error creating problem:", error);
        alert("Failed to create problem. Please try again.");
      }
    },
  });

  const [createRootProblem] = useMutation(CREATE_ROOT_PROBLEM, {
    refetchQueries: [
      {
        query: GET_ALL_PROBLEMS,
        variables: { userId: localStorage.getItem("userId") },
      },
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error("Error creating problem:", error);
      alert("Failed to create problem. Please try again.");
    },
  });

  const tooltips = {
    ExecutiveSupport:
      "Executive support refers to the C-suite of the system and is weighted more heavily, because this is where capital resource decisions are typically made.",
    ServiceLineAdministrativeEngagement:
      "Service Line Administration Engagement refers to key personnel in the domain your problem resides, such as oncology, orthopedics or mental health.",
    ClinicianEngagement:
      "Clinicians include the people directly involved in patient care, diagnosis and treatment.",
    StrengthOfSupportingEvidence:
      "Strength of supporting evidence refers to availability of quality data that validates the efficacy of your approach.",
    ImpactToTheQuadrupleAim:
      "Impact to the Quadruple Aim refers to the standard healthcare goals of improving population health, enhancing patient experience, reducing per capita costs and improving provider well-being.",
    PotentialToScaleUp:
      "Potential to scale up refers to the accessibility, generalizability and reproducibility of the solution.",
  };

  const calculateAdjustedScore = () => {
    let totalScore = 0;
    let totalWeight = 0;

    for (const key in scores) {
      if (!notApplicable[key]) {
        totalScore += scores[key] * weightFactors[key];
        totalWeight += weightFactors[key];
      }
    }

    const adjustedScore = totalWeight > 0 ? totalScore / totalWeight : 0;
    return Math.round(adjustedScore);
  };

  const calculateGrade = (score) => {
    if (score >= 90) return "A";
    if (score >= 80) return "B";
    if (score >= 70) return "C";
    if (score >= 60) return "D";
    return "F";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value);

    if (value === "") {
      setScores((prevScores) => ({
        ...prevScores,
        [name]: "",
      }));
    } else if (!isNaN(numericValue)) {
      const constrainedValue = Math.min(100, Math.max(1, numericValue));
      setScores((prevScores) => ({
        ...prevScores,
        [name]: constrainedValue,
      }));
    }
  };

  const handleNotApplicableChange = (e) => {
    const { name, checked } = e.target;

    setNotApplicable((prev) => {
      const updatedNotApplicable = { ...prev, [name]: checked };

      if (checked) {
        setScores((prevScores) => ({
          ...prevScores,
          [name]: 0,
        }));
      }

      return updatedNotApplicable;
    });
  };

  useEffect(() => {
    const score = calculateAdjustedScore();
    setAdjustedScore(score);
    const Grade = calculateGrade(score);
    setGrade(Grade);
    setStatus(Grade === "A" || Grade === "B" ? "GO" : "NO-GO");
  }, [scores, notApplicable]);

  const handleSubmit = async () => {
    const missingFields = [];

    Object.keys(scores).forEach((key) => {
      if (
        !notApplicable[key] &&
        (!scores[key] || scores[key] > 100 || scores[key] < 0)
      ) {
        missingFields.push(`\u2022 ${key.split(/(?=[A-Z])/).join(" ")}`);
      }
    });

    if (missingFields.length > 0) {
      const toastContent = (
        <div>
          <p className="text-primary text-bold">
            All Fields Are Required and should be above 0 and below 100
          </p>
          <p className="text-red-400">Missing/ Incorrect fields:</p>
          <ul>
            {missingFields.map((field, index) => (
              <li className="text-black" key={index}>
                {field}
              </li>
            ))}
          </ul>
        </div>
      );
      showToast(toastContent, "error");

      return;
    }

    const processedScores = {};
    Object.entries(scores).forEach(([key, value]) => {
      processedScores[key] = value === "" ? 0 : value;
    });

    if (status === "NO-GO") {
      setModalOpen(true);
    } else {
      try {
        setDialogOpen(true);
        await generateMap({ variables: { challenge, problemStatement } });
      } catch (error) {
        console.error("Error generating map:", error);
        setDialogOpen(false);
        showToast("Failed to generate map. Please try again.", "error");
      }
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleProceedAnyway = async () => {
    setModalOpen(false);
    try {
      setDialogOpen(true);
      await generateMap({ variables: { challenge, problemStatement } });
    } catch (error) {
      console.error("Error generating map:", error);
      setDialogOpen(false);
      showToast("Failed to generate map. Please try again.", "error");
    }
  };

  return (
    <div className="w-screen h-screen bg-back font-roboto flex justify-between text-base">
      <div className="w-[22%]">
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      <ToastAlert />
      <div className="h-full flex flex-col w-[50%] bg-img">
        <div className="w-full h-[70px] bg-primary flex justify-between px-4 items-center"></div>
        <div className="flex flex-col justify-start py-6 px-10">
          <h1 className="text-[28px] py-2">Demand Index</h1>
          <div className="text-[#49454f] text-xs font-normal leading-none tracking-wide">
            Insert a score from 1 to 100 for each demand domain, if it’s
            applicable.
          </div>

          <div className="py-7 w-full flex flex-col space-y-5 justify-center items-center">
            {Object.keys(weightFactors).map((key) => (
              <div key={key} className="flex justify-between w-full">
                <StyledTooltip title={tooltips[key]}>
                  <div className="w-[55%] h-12 bg-[#cac4d0] rounded-t-[5px] border-b-[1px] border-black flex justify-start px-4 items-center">
                    {key.split(/(?=[A-Z])/).join(" ")}{" "}
                  </div>
                </StyledTooltip>
                <div className="flex flex-row space-x-1 w-[15%] justify-end pl-5">
                  <input
                    type="number"
                    name={key}
                    value={scores[key]}
                    onChange={handleChange}
                    className={`w-full h-[42px] rounded-[10px] border border-[#cac4d0] flex items-center pl-4 font-semibold ${
                      notApplicable[key] ? "bg-gray-200" : "bg-[#fef7ff]"
                    }`}
                    min="1"
                    max="100"
                    disabled={notApplicable[key]}
                  />
                </div>
                <div className="w-[25%] h-[42px] rounded-[10px] flex flex-row justify-center items-center">
                  <div className="font-medium text-end">Not Applicable</div>
                  <CustomCheckbox
                    name={key}
                    checked={notApplicable[key]}
                    onChange={handleNotApplicableChange}
                    inputProps={{ "aria-label": "custom checkbox" }}
                  />
                </div>
              </div>
            ))}
            <div className="pt-12 w-full">
              {AdjustedScore > 0 && (
                <div className="bg-white w-full h-[152px] flex flex-col items-center justify-center border-b border-l border-r">
                  <div className="flex items-center w-full -mt-5">
                    <div className="border-t border-gray-300 flex-grow mr-3"></div>
                    <div className="text-center text-[#49454f] text-2xl font-normal leading-loose">
                      Demand’s status
                    </div>
                    <div className="border-t border-gray-300 flex-grow ml-3"></div>
                  </div>

                  <div
                    className={`${
                      status === "GO" ? "text-[#1DAD2C]" : "text-[#8E1C10]"
                    } text-[36px] flex justify-center font-semibold items-center font-medium w-full h-full -mt-4`}
                  >
                    <span>{status}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-4 justify-end">
            <button
              className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium"
              onClick={handleSubmit}
            >
              Generate the Map and Find the Nudge Types
            </button>
          </div>
        </div>
      </div>

      {/* Dialog for map generation */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          style: {
            borderRadius: 28,
            background: "#ece6f0",
            width: "560px",
            height: "260px",
          },
        }}
      >
        <DialogContent className="w-[540px] h-[200px] flex flex-col items-center">
          <CircularProgress sx={{ color: "#65558f" }} />
          <div className="text-black text-2xl font-normal">
            <br />
            AgileNudge+ is generating the map for your problem statement!
          </div>
          <div className="text-[#49454F] font-semibold mt-4">
            The map includes stakeholders, behaviors, and nudge types for your
            problem statement.
          </div>
        </DialogContent>
      </Dialog>

      {/* Dialog for NO-GO status */}
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        PaperProps={{
          style: {
            borderRadius: 28,
            background: "#ece6f0",
            width: "560px",
            height: "224px",
          },
        }}
      >
        <DialogTitle>
          <div>
            What is the <span className="text-[#8E1C10] font-bold">NO-GO</span>{" "}
            status?
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="font-semibold">
            You need to build demand for your solution separately, before
            returning to AgileNudge+ to continue the process!
          </div>
          <div className="flex justify-end mt-12 px-6">
            <button
              className="rounded-full bg-bcolor text-white font-medium tracking-tight p-2"
              onClick={handleProceedAnyway}
            >
              Continue Anyway
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DemandIndex;
