import React, { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import videoThumbnail from "../../Assets/Images/videobg.png"; // Placeholder image for the videos
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
const TutorialHowTo = () => {
  const [open, setOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState("79.5%");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");

  useEffect(() => {
    // Adjust the content width based on the sidebar's open/closed state
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  const videos = [
    {
      id: 1,
      videoLink: "https://www.youtube.com/watch?v=9BJy36xgH3Y",
      title: "About Us",
      description: "Gives an idea about the software",
      duration: "47 secs",
      thumbnail: videoThumbnail,
    },
    {
      id: 2,
      videoLink: "https://www.youtube.com/watch?v=1o0swnLHdpI",
      title: " Agile Science explainer",
      description: "Explains agile science",
      duration: "35 secs",
      thumbnail: videoThumbnail,
    },
    {
      id: 3,
      videoLink: "https://www.youtube.com/watch?v=hlqQmytK5JE",
      title: " Demand Index",
      description: "Explains the concept of Demand Index",
      duration: "28 secs",
      thumbnail: videoThumbnail,
    },
    {
      id: 4,
      videoLink: "https://www.youtube.com/watch?v=IQMgjc5h8aYE",
      title: "Problem Statement",
      description: "Explains how to create a problem statement in the Agile Nudge PLus software",
      duration: "43 secs",
      thumbnail: videoThumbnail,
    },
    {
      id: 5,
      videoLink: "https://www.youtube.com/watch?v=dZ7QEwLlBAA",
      title: "Demand Index 2",
      description: "Explains how to finalize the demand index values in the software",
      duration: "115 secs",
      thumbnail: videoThumbnail,
    },
    {
      id: 6,
      videoLink: "https://youtu.be/imRpC1CfoYs",
      title: "Stakeholder Map",
      description: "Explains how to create a stakeholder and map the problem behavior, nudge type etc ",
      duration: "38 secs",
      thumbnail: videoThumbnail,
    },
    {
      id: 7,
      videoLink: "https://youtu.be/fDXBTAD4fyM",
      title: "Nudge Design ",
      description: "Explains how to design a nudge and create sprints for your nudge",
      duration: "102 secs",
      thumbnail: videoThumbnail,
    },
  ];

  const handleVideoSelect = (video) => {
    setSelectedVideo(video.videoLink);
    setSelectedVideoTitle(video.title);
  };

  return (
    <div className="flex flex-row h-screen font-roboto bg-img text-base">
      {/* Sidebar */}
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      {/* Main Content */}
      <div
        className="flex flex-col justify-start items-stretch" // Change to "items-stretch"
        style={{ width: contentWidth }}
      >
        <div className="w-full h-[70px] bg-primary text-white font-medium flex items-center pl-10">
          <NavLink to={"/tutorial"}>
            <button className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center space-x-1">
              <ArrowBackIcon /> <h1>Back</h1>
            </button>
          </NavLink>
          <h1 className="text-[28px] opacity-50 ml-5">
            Tutorials and Resources
          </h1>
          <h1 className="text-[28px] ml-2"> &gt; How-to Videos</h1>
        </div>

        {/* Video List and Player */}
        <div className="flex flex-row h-[90%] pt-10">
          {/* Video List on the Left */}
          <div className="w-[300px] h-[90%] p-4 overflow-y-auto scrollbar border-r-[1px] border-gray-300">
            <h2 className="text-[22px] font-medium mb-4">Video's List</h2>
            <ul className="space-y-4">
              {videos.map((video) => (
                <li
                  key={video.id}
                  onClick={() => handleVideoSelect(video)}
                  className={`h-[72px] px-4 py-2 flex items-center gap-4 rounded-[15px] cursor-pointer ${
                    selectedVideo === video.videoLink
                      ? "bg-bcolor text-white"
                      : "bg-[#FEF7FF] text-[#1d1b20]"
                  }`}
                >
                  <div className="flex-col justify-center items-start inline-flex">
                    <div className="w-10 h-10 bg-[#eaddff] rounded-full flex items-center justify-center">
                      <img
                        className="w-full h-full rounded-full"
                        src={video.thumbnail}
                        alt="Video thumbnail"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-start flex-grow">
                    <div className="text-base font-normal">
                      {video.title.substring(0, 13) + "..."}
                    </div>
                    <div className="text-sm font-normal">
                      {video.description.substring(0, 15) + "..."}
                    </div>
                  </div>
                  <div className="text-right text-[11px] font-medium">
                    {video.duration}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Video Player on the Right */}
          <div className="w-[73%] flex flex-col items-stretch px-6 h-full">
            {/* Selected Video Title */}
            {selectedVideoTitle && (
              <div className="w-full h-[64px] bg-bcolor text-white text-lg font-medium flex items-center px-4 rounded-t-[15px]">
                {selectedVideoTitle}
              </div>
            )}
            {selectedVideo ? (
              <ReactPlayer
                url={selectedVideo} // YouTube video URL
                playing // Autoplay the video
                controls={false} // Hide controls
                width="100%"
                height="80%" // Set to fill available height
                className="rounded-b-[15px] shadow-lg border-[1px] border-black"
              />
            ) : (
              <div className="flex justify-center items-center w-full h-[80%] bg-gray-200 rounded-md shadow-lg">
                <img
                  src={videoThumbnail}
                  alt="Select a video"
                  className="w-full h-full opacity-50"
                />
                <h3 className="absolute text-gray-500 text-xl">
                  Select a video to play
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialHowTo;
