import React from "react";
import { Tooltip } from "@mui/material";

const StyledTooltip = ({ title, children }) => {
  return (
    <Tooltip
      title={
        <>
          {title.split("\n").map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </>
      }
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#e8def8", // Dark background
            color: "#334155", // White text
            fontSize: "18px",
            padding: "8px 16px",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Custom shadow
          },
        },
        arrow: {
          sx: {
            color: "#e8def8", // Match arrow color to tooltip background
          },
        },
      }}
      arrow // Show an arrow for the tooltip
    >
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
