import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import TreeDiagram from "./TreeDiagram";
import TaskMonitor from "./TaskMonitor";
import SprintCalendar from "./SprintCalendar";
import {
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popper,
  TextField,
  styled,
} from "@mui/material";

import { auth } from "../../firebase";
import {
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import MenuIcon from "@mui/icons-material/Menu";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import {
  GET_PROBLEM,
  UPDATE_ROOT_PROBLEM,
  GET_ALL_SPRINTS,
  DELETE_ROOT_PROBLEM,
  DELETE_SPRINT,
  GET_ALL_USERS,
  CREATE_USER,
  UPDATE_SPRINT
} from "../../Graphql/User/Query";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import { ReactComponent as Print } from "../../Assets/Svgs/printer.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Svgs/delete.svg";
import EditOutlined from "@mui/icons-material/EditOutlined";
import cloneDeep from "lodash/cloneDeep";
import SearchableDropdown from "./SearchableDropdown";
import { ReactComponent as Mail } from "../../Assets/Svgs/mail.svg";
import Sidebar from "./SideBar";
import NewProblemAlert from "./NewProblemAlert";
import StyledTooltip from "./StyledTooltip";


const TreeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stakeholderRefs = useRef([]);
  const behaviorRefs = useRef([]);
  const nudgeRefs = useRef([]);
  const sprinterRefs = useRef([]);
  const { problemId, sprintfromsidebar } = location.state || {};

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [getProblem, { data: problemData }] = useLazyQuery(GET_PROBLEM);
  const [updateRootProblem] = useMutation(UPDATE_ROOT_PROBLEM);
  const [rootProblem, setRootProblem] = useState("");
  const [data, setData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [currentStakeholder, setCurrentStakeholder] = useState(null);
  const [isStakeholderEdit, setIsStakeholderEdit] = useState(false);
  const newElementRef = useRef(null);
  const [newStakeholderId, setNewStakeholderId] = useState(null);
  const [stakeholderId, setStakeholderId] = useState(null);
  const [behaviorId, setBehaviorId] = useState(null);
  const [nudgeId, setNudgeId] = useState(null);
  const [showBehaviorButtons, setShowBehaviorButtons] = useState(false);
  const [activeSprint, setActiveSprint] = useState(null);
  const [isSprintMonitor, setIsSprintMonitor] = useState(false);
  const [yourPassword, setYourPassword] = useState(null);
  const [selectedStakeholderIndex, setSelectedStakeholderIndex] =
    useState(null);
  const nudgeOptions = [
    "Messenger",
    "Incentives",
    "Norms",
    "Default",
    "Salience",
    "Priming",
    "Affect",
    "Commitment",
    "Ego",
  ];
  const StyledMenuItem = styled(MenuItem)({
    padding: "15px",
    borderBottom: "1px solid #CAC4D0",
    display: "flex",
    alignItems: "center",
  });
  const CustomPopper = (props) => {
    return (
      <Popper {...props} style={{ width: 300 }} placement="bottom-start" />
    );
  };
  const CustomPaper = (props) => (
    <Paper {...props} style={{ backgroundColor: "#6750A4", color: "#fff" }} />
  );
  const { data: sprintData } = useQuery(GET_ALL_SPRINTS);
  const [sprints, setSprints] = useState([]);
  const [open, setOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState("80%");
  const [leftWidth, setLeftWidth] = useState(50); // Initial width for left div (percentage)
  const [activeTab, setActiveTab] = useState("existing"); // Define the activeTab state
  const [showDialog, setShowDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null); // Anchor element for the menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    data: usersData,
    loading: userLoading,
    refetch: refetchUsers,
  } = useQuery(GET_ALL_USERS);
  const [createUser] = useMutation(CREATE_USER);
  const [selectedSprinters, setSelectedSprinters] = useState({});

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const [deleteRootProblem] = useMutation(DELETE_ROOT_PROBLEM, {
    onCompleted: () => {
      showToast("Problem deleted successfully", "success");
      setDeleteDialogOpen(false);
    },
    onError: (error) => {
      console.error("Error deleting problem:", error);
      showToast("Failed to delete problem", "error");
    },
  });

  const [deleteSprint] = useMutation(DELETE_SPRINT, {
    onCompleted: () => {
      showToast("Sprint deleted successfully", "success");
      setDeleteDialogOpen(false);
    },
    onError: (error) => {
      console.error("Error deleting sprint:", error);
      showToast("Failed to delete sprint", "error");
    },
  });
  const [updateSprintMutation] = useMutation(UPDATE_SPRINT, {
    onCompleted: () => {
      console.log("Sprint status updated successfully");
    },
    onError: (error) => {
      console.error("Error updating sprint status:", error);
    },
  });
  const updateSprintStatus = async (sprint, updateSprintMutation) => {
    if (sprint.status === "Success" || sprint.status === "Terminated") {
      return; // Skip if already success or terminated
    }
  
    const today = new Date();
    const endDate = new Date(sprint.endDate);
  
    if (today < endDate) {
      return; // Skip if the sprint hasn't ended yet
    }
  
    const allTasksSuccess = sprint.tasks.every((task) => task.status === "Success");
    const allTasksNotSuccess = sprint.tasks.every((task) => task.status !== "Success");
  
    let newStatus = null;
  
    if (allTasksSuccess) {
      newStatus = "Success";
    } else if (allTasksNotSuccess) {
      newStatus = "Terminated";
    }
  
    if (newStatus) {
      const updatedSprintData = {
        ...sprint,
        status: newStatus, // Update status
      };
  
      try {
        await updateSprintMutation({
          variables: {
            sprintId: sprint.id,
            data: updatedSprintData, // Pass the full sprint data
          },
        });
        console.log(`Sprint ${sprint.id} updated to ${newStatus}`);
      } catch (err) {
        console.error(`Error updating sprint ${sprint.id}:`, err);
      }
    }
  };
  const handleMouseDown = (e) => {
    setIsDragging(true);
    e.preventDefault(); // Prevents text selection on the page
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const newLeftWidth = (event.clientX / window.innerWidth) * 100;
      if (newLeftWidth > 10 && newLeftWidth < 90) {
        setLeftWidth(newLeftWidth);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setIsMenuOpen(false);
  };
  useEffect(() => {
    if (sprints.length > 0) {
      sprints.forEach(async (sprint) => {
        await updateSprintStatus(sprint, updateSprintMutation);
      });
    }
  }, [sprints]);
  // Add event listeners for resizing
  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    // Adjust the content width based on the sidebar's open/closed state
    setContentWidth(open ? "80%" : "96.5%");
  }, [open]);

  useEffect(() => {
    if (sprintData) {
      setSprints(sprintData.getAllSprints);
    }
  }, [sprintData]);

  useEffect(() => {
    const initializeData = async () => {
      if (problemId) {
        // Fetch the problem data; the result will populate `problemData`
        await getProblem({
          variables: {
            userId: sprintfromsidebar
              ? sprintfromsidebar.userId
              : localStorage.getItem("userId"),
            problemId,
          },
        });
      }
    };
    setIsSprintMonitor(false);
    initializeData();
  }, [problemId, sprintfromsidebar]);

  // Set rootProblem and data only when problemData is loaded
  useEffect(() => {
    if (problemData?.getProblem) {
      setRootProblem(problemData.getProblem.name);
      setData(problemData.getProblem);
    }
    // If there's a sprint, set both the sprint and the problem data
    if (data && sprintfromsidebar) {
      setIsSprintMonitor(false);
      handleSprintClick(sprintfromsidebar.id, sprintfromsidebar);
    }
    // Clear the location state after initial setup
    navigate(location.pathname, { replace: true, state: {} });
  }, [problemData, sprintfromsidebar]);

  useEffect(() => {
    if (newStakeholderId) {
      handleNodeClick(newStakeholderId);
      setNewStakeholderId(null);
    }
  }, [newStakeholderId]);

  useEffect(() => {
    if (activeSprint) {
      setIsSprintMonitor(true);
    }
  }, [activeSprint]);
  useEffect(() => {
    // Initialize selected sprinters based on existing sprinter names in the data
    if (data && usersData && usersData.allUsers) {
      const newSelectedSprinters = {};

      data.children.forEach((stakeholder, stakeholderIndex) => {
        stakeholder.children.forEach((behavior, behaviorIndex) => {
          behavior.children.forEach((nudge, nudgeIndex) => {
            const sprinterName = nudge.children[0]?.name;
            const matchedUser = usersData.allUsers.find(
              (user) => user.username === sprinterName
            );

            if (matchedUser) {
              newSelectedSprinters[
                `${stakeholderIndex}-${behavior.id}-${nudge.id}`
              ] = matchedUser;
            }
          });
        });
      });

      setSelectedSprinters(newSelectedSprinters);
    }
  }, [data, usersData]);
  useEffect(() => {
    if (!localStorage.getItem("doNotShowNewProblemDialog")) {
      setShowDialog(true);
    }
  }, []);
  const handleDialogClose = (doNotShowAgain) => {
    if (doNotShowAgain) {
      localStorage.setItem("doNotShowNewProblemDialog", "true");
    }
    setShowDialog(false);
  };
  const handleDeleteClick = (
    type,
    stakeholderId = null,
    behaviorId = null,
    nudgeId = null
  ) => {
    console.log(type);
    setDeleteType(type);
    setStakeholderId(stakeholderId);
    setBehaviorId(behaviorId);
    setNudgeId(nudgeId);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      switch (deleteType) {
        case "sprint":
          await deleteSprint({
            variables: { sprintId: activeSprint.sprintId },
          });
          window.location.reload();
          break;
        case "problem":
          const associatedSprints = sprints.filter(
            (sprint) => sprint.problemId === data.id
          );
          for (const sprint of associatedSprints) {
            await deleteSprint({ variables: { sprintId: sprint.id } });
          }
          await deleteRootProblem({
            variables: {
              userId: localStorage.getItem("userId"),
              problemId: data.id,
            },
          });
          window.location.reload();
          break;
        case "stakeholder":
          handleDelete("STAKEHOLDER", stakeholderId, null, null, null);
          break;
        case "behavior":
          handleDelete("BEHAVIOR", stakeholderId, behaviorId, null, null);
          break;
        case "nudge":
          handleDelete("NUDGE", stakeholderId, behaviorId, nudgeId, null);
          break;
        default:
          console.error("Unknown delete type:", deleteType);
      }

      setDeleteDialogOpen(false);
    } catch (error) {
      console.error(`Error deleting ${deleteType}:`, error);
      showToast(`Failed to delete ${deleteType}`, "error");
    }
  };
  const addStakeholder = () => {
    const newStakeholder = {
      id: uuidv4(),
      name: `Stakeholder ${data.children.length + 1}`,
      children: [],
    };
    const updatedData = {
      ...data,
      children: [...data.children, newStakeholder],
    };

    setData(updatedData);

    updateRootProblem({
      variables: {
        userId: localStorage.getItem("userId"),
        problemId: updatedData.id,
        data: updatedData,
      },
      refetchQueries: [
        {
          query: GET_PROBLEM,
          variables: { userId: localStorage.getItem("userId"), problemId },
        },
      ],
    })
      .then(() => {
        setNewStakeholderId(newStakeholder.id);
      })
      .catch((err) => console.error("Error updating stakeholder:", err));
  };

  const addBehavior = async (stakeholderIndex, bname, btarget) => {
    setShowBehaviorButtons(false);

    return new Promise((resolve) => {
      setData((prevData) => {
        const updatedData = cloneDeep(prevData);

        const stakeholder = updatedData.children[stakeholderIndex];
        const behaviorIndex = stakeholder.children.length;

        // Create a new behavior with an empty nudge and sprinter
        const newBehavior = {
          id: uuidv4(),
          name: bname,
          target: btarget,
          children: [],
        };

        stakeholder.children.push(newBehavior);
        resolve(behaviorIndex); // Resolve with the index of the new behavior

        setTimeout(
          () => newElementRef.current?.scrollIntoView({ behavior: "smooth" }),
          100
        );

        return updatedData;
      });
    });
  };
  const handleAddManualBehavior = async (stakeholderIndex) => {
    // Add behavior and get the behavior index
    const behaviorIndex = await addBehavior(stakeholderIndex, "", "");
    setIsSearchModalOpen(false);
    // Focus on the newly added behavior
    setTimeout(() => {
      const el = behaviorRefs.current[`${stakeholderIndex}-${behaviorIndex}`];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        el.focus();
      }
    }, 100);
  };
  const addNudge = async (stakeholderIndex, behaviorIndex, nudgeType) => {
    setData((prevData) => {
      const updatedData = cloneDeep(prevData);
      const behavior =
        updatedData.children[stakeholderIndex].children[behaviorIndex];
      behavior.children.push({
        id: uuidv4(),
        name: nudgeType.trim(),
        children: [{ id: uuidv4(), name: "", tasks: [] }],
      });
      return updatedData;
    });
  };

  const findIndices = (nodeId, treeData) => {
    let stakeholderIndex = null;
    let behaviorIndex = null;
    let nudgeIndex = null;

    treeData.children.forEach((stakeholder, sIndex) => {
      if (stakeholder.id === nodeId) {
        stakeholderIndex = sIndex;
      }

      stakeholder.children.forEach((behavior, bIndex) => {
        if (behavior.id === nodeId) {
          stakeholderIndex = sIndex;
          behaviorIndex = bIndex;
        }

        behavior.children.forEach((nudge, nIndex) => {
          if (nudge.children[0].id === nodeId) {
            stakeholderIndex = sIndex;
            behaviorIndex = bIndex;
            nudgeIndex = nIndex;
          }
        });
      });
    });

    return { stakeholderIndex, behaviorIndex, nudgeIndex };
  };
  const addChild = (node) => {
    const userId = localStorage.getItem("userId");
    if (!data.admins.includes(userId)) {
      return;
    }
    setIsSprintMonitor(false);

    const { stakeholderIndex, behaviorIndex, nudgeIndex } = findIndices(
      node.data.id,
      data
    );

    const missingFields = [];

    // Case 1: Check if stakeholder has a name
    if (node.depth === 1) {
      const stakeholder = data.children[stakeholderIndex];
      if (!stakeholder?.name) {
        missingFields.push("Stakeholder Name");
      }
    }

    // Case 2: Check if behavior has a name and target
    if (node.depth === 2) {
      const behavior = data.children[stakeholderIndex]?.children[behaviorIndex];
      if (behavior) {
        if (!behavior.name) {
          missingFields.push("Problem Behavior");
        }
        if (!behavior.target) {
          missingFields.push("Target Behavior");
        }
      } else {
        missingFields.push(
          "At least one Behavior is required for the Stakeholder"
        );
      }
    }

    // Case 3: Check if nudge has a name
    if (node.depth === 3) {
      const nudge =
        data.children[stakeholderIndex]?.children[behaviorIndex]?.children[
          nudgeIndex
        ];
      if (nudge && !nudge.name) {
        missingFields.push("Nudge Type");
      } else if (!nudge) {
        missingFields.push("At least one Nudge is required for the Behavior. ");
      }
    }

    // Case 4: Check if sprinter has a name
    if (node.depth === 4) {
      const sprinter =
        data.children[stakeholderIndex]?.children[behaviorIndex]?.children[
          nudgeIndex
        ]?.children[0];
      if (sprinter && !sprinter.name) {
        missingFields.push("Sprinter");
      } else if (!sprinter) {
        missingFields.push("At least one Sprinter is required for the Nudge");
      }
    }

    // If any required fields are missing, display a toast and block adding the child
    if (missingFields.length > 0) {
      showToast(
        <div>
          <p className="font-semibold">Please complete the following fields:</p>
          <ul>
            {missingFields.map((field, index) => (
              <li key={index} className="text-black">
                {field}
              </li>
            ))}
          </ul>
        </div>,
        "error"
      );
      return; // Prevent adding the child if fields are incomplete
    }

    // Rest of the addChild logic for adding stakeholder, behavior, nudge, or sprint
    if (node.depth === 0) {
      addStakeholder();
    } else if (node.depth === 1) {
      setSelectedStakeholderIndex(stakeholderIndex);
      setShowBehaviorButtons(true);
      handleOpenSearchModal(stakeholderIndex, null);
    } else if (node.depth === 2) {
      setSelectedStakeholderIndex(stakeholderIndex);
      addNudge(stakeholderIndex, behaviorIndex, "");
    } else if (node.depth === 4) {
      setSelectedStakeholderIndex(null);
      setIsSprintMonitor(false);
      addSprint(stakeholderIndex, behaviorIndex, nudgeIndex);
    }
  };
  const handleOpenSearchModal = (stakeholderIndex, behaviorIndex = null) => {
    setCurrentStakeholder({ stakeholderIndex, behaviorIndex });
    setIsSearchModalOpen(true);
  };

  const handleCloseSearchModal = () => setIsSearchModalOpen(false);

  const handleEdit = (
    type,
    stakeholderId,
    behaviorId,
    nudgeId,
    sprinterId,
    name,
    ismanual
  ) => {
    const sprintStartedForNudge = sprints.some(
      (sprint) => sprint.nudgeId === nudgeId && new Date(sprint.startDate) <= new Date()
    );
  
    if (sprintStartedForNudge) {
      showToast("Editing is disabled for this nudge as a sprint has already started.", "error");
      return; // Prevent edits
    }
    const updatedChildren = data.children.map((stakeholder) => {
      if (stakeholder.id !== stakeholderId) return stakeholder;

      if (type === "STAKEHOLDER") return { ...stakeholder, name };

      const updatedBehaviors = stakeholder.children.map((behavior) => {
        if (behavior.id !== behaviorId) return behavior;

        if (type === "BEHAVIOR") {
          if (nudgeId === "target") return { ...behavior, target: name };
          return { ...behavior, name };
        }

        const updatedNudges = behavior.children.map((nudge) => {
          if (nudge.id !== nudgeId) return nudge;

          if (type === "NUDGE") return { ...nudge, name };

          const updatedSprints = nudge.children.map((sprinter) => {
            if (sprinter.id !== sprinterId) return sprinter;
            return {
              ...sprinter,
              sprinterId: name.id,
              name: name.username,
              ismanual,
            };
          });
          return { ...nudge, children: updatedSprints };
        });
        return { ...behavior, children: updatedNudges };
      });
      return { ...stakeholder, children: updatedBehaviors };
    });

    const updatedData = { ...data, children: updatedChildren };
    setData(updatedData);
    return updatedData;
  };

  const handleDelete = (
    type,
    stakeholderId,
    behaviorId,
    nudgeId,
    sprinterId
  ) => {
    // Clone the data to avoid direct mutation
    const updatedData = cloneDeep(data);
    removeSprints(updatedData);
    // Map over the stakeholders
    updatedData.children = updatedData.children
      .map((stakeholder) => {
        if (stakeholder.id !== stakeholderId) return stakeholder;

        if (type === "STAKEHOLDER") {
          // If deleting a stakeholder, remove the stakeholder from the list
          setSelectedStakeholderIndex(null);
          return null;
        }

        // Map over the behaviors
        const updatedBehaviors = stakeholder.children.filter((behavior) => {
          if (behavior.id === behaviorId && type === "BEHAVIOR") return false;

          if (type === "NUDGE") {
            // Filter out the nudge to be deleted
            behavior.children = behavior.children.filter(
              (nudge) => nudge.id !== nudgeId
            );
          } else if (type === "SPRINTER") {
            // Filter out the sprinter to be deleted
            behavior.children.forEach((nudge) => {
              nudge.children = nudge.children.filter(
                (sprinter) => sprinter.id !== sprinterId
              );
            });
          }

          return true;
        });

        return { ...stakeholder, children: updatedBehaviors };
      })
      .filter(Boolean); // Filter out null values (for deleted stakeholders)

    // Set the updated data
    setData(updatedData);

    // Call the mutation to update the problem in the backend
    updateRootProblem({
      variables: {
        userId: localStorage.getItem("userId"),
        problemId: updatedData.id,
        data: updatedData,
      },
      refetchQueries: [
        {
          query: GET_PROBLEM,
          variables: { userId: localStorage.getItem("userId"), problemId },
        },
      ],
    }).catch((err) => console.error("Error deleting:", err));
  };

  const removeSprints = (node) => {
    if (!node || !node.children) return;

    node.children = node.children.filter((child) => child.type !== "SPRINT");

    node.children.forEach((child) => {
      removeSprints(child);
    });
  };
  const handleUpdatedData = (type) => {
    // Check for selectedStakeholderIndex to avoid undefined errors
    if (selectedStakeholderIndex === null) return;
    // Clone the data and remove sprints before saving
    const cleanedData = cloneDeep(data);
    removeSprints(cleanedData);
    if (type === "stakeholder") {
      const updatedData = {
        id: cleanedData.id,
        name: rootProblem,
        children: cleanedData.children,
      };

      // Update root problem and refetch data
      updateRootProblem({
        variables: {
          userId: localStorage.getItem("userId"),
          problemId: data.id,
          data: updatedData,
        },
        refetchQueries: [
          {
            query: GET_PROBLEM,
            variables: { userId: localStorage.getItem("userId"), problemId },
          },
        ],
      })
        .then(() => {
          // Refetch problem data to get updated tree and sprinters
          getProblem({
            variables: { userId: localStorage.getItem("userId"), problemId },
          });
        })
        .catch((err) => console.error("Error updating stakeholder:", err));
      setIsStakeholderEdit(false);
      return;
    }
    const selectedStakeholder = data.children[selectedStakeholderIndex];
    const missingFields = [];

    // Validate fields
    if (!selectedStakeholder.name) missingFields.push("Stakeholder Name");
    if (selectedStakeholder.children.length === 0) {
      missingFields.push(
        "At least one behavior is required for the stakeholder"
      );
    }

    selectedStakeholder.children.forEach((behavior, behaviorIndex) => {
      if (!behavior.name)
        missingFields.push(`Problem Behavior for ${behaviorIndex + 1}`);
      if (!behavior.target)
        missingFields.push(`Target Behavior for ${behaviorIndex + 1}`);
      if (behavior.children.length === 0) {
        missingFields.push(
          `At least one nudge is required for Behavior ${behaviorIndex + 1}. Click on the + in front of the behavior on the map to add a nudge type`
        );
      }

      behavior.children.forEach((nudge, nudgeIndex) => {
        if (!nudge.name) {
          missingFields.push(
            `Nudge ${nudgeIndex + 1} for Behavior ${behaviorIndex + 1}`
          );
        }
        if (!nudge.children[0]?.name) {
          missingFields.push(
            `Sprinter for Nudge ${nudgeIndex + 1} Behavior ${behaviorIndex + 1}`
          );
        }
      });
    });

    if (missingFields.length > 0) {
      showToast(
        <div>
          <p className="font-semibold">Missing fields:</p>
          <ul>
            {missingFields.map((field, index) => (
              <li className="text-black" key={index}>
                {field}
              </li>
            ))}
          </ul>
        </div>,
        "error"
      );
      return;
    }

    const updatedData = {
      id: cleanedData.id,
      name: rootProblem,
      children: cleanedData.children,
    };

    // Update root problem and refetch data
    updateRootProblem({
      variables: {
        userId: localStorage.getItem("userId"),
        problemId: data.id,
        data: updatedData,
      },
      refetchQueries: [
        {
          query: GET_PROBLEM,
          variables: { userId: localStorage.getItem("userId"), problemId },
        },
      ],
    })
      .then(() => {
        // Refetch problem data to get updated tree and sprinters
        getProblem({
          variables: { userId: localStorage.getItem("userId"), problemId },
        });
      })
      .catch((err) => console.error("Error updating stakeholder:", err));

    setSelectedStakeholderIndex(null);
  };

  const handleNodeClick = (id) => {
    const userId = localStorage.getItem("userId");
    if (!data.admins.includes(userId)) {
      return;
    }
    setIsSprintMonitor(false);
    const highlightElement = (element) => {
      if (element) {
        element.classList.add("highlight-border");
        setTimeout(() => {
          element.classList.remove("highlight-border");
        }, 5000); // Remove after 5 seconds
      }
    };

    // Check if it's a Stakeholder
    const stakeholderIndex = data.children.findIndex(
      (stakeholder) => stakeholder.id === id
    );
    if (stakeholderIndex !== -1) {
      setSelectedStakeholderIndex(stakeholderIndex);

      setTimeout(() => {
        const el = stakeholderRefs.current[stakeholderIndex];
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
          highlightElement(el);
        }
      }, 0);
      return;
    }

    // Check if it's a Behavior
    const behaviorInfo = data.children
      .map((stakeholder, stakeholderIndex) => ({
        stakeholderIndex,
        behaviorIndex: stakeholder.children.findIndex(
          (behavior) => behavior.id === id
        ),
      }))
      .find((info) => info.behaviorIndex !== -1);

    if (behaviorInfo) {
      setSelectedStakeholderIndex(behaviorInfo.stakeholderIndex);

      setTimeout(() => {
        const el =
          behaviorRefs.current[
            `${behaviorInfo.stakeholderIndex}-${behaviorInfo.behaviorIndex}`
          ];
        el.scrollIntoView({ behavior: "smooth" });
        highlightElement(el);
      }, 0);
      return;
    }

    // Check if it's a Nudge
    const nudgeInfo = data.children
      .map((stakeholder, stakeholderIndex) => ({
        stakeholderIndex,
        behaviorInfo: stakeholder.children
          ?.map((behavior, behaviorIndex) => ({
            behaviorIndex,
            nudgeIndex: behavior.children?.findIndex(
              (nudge) => nudge.id === id
            ),
          }))
          .find((info) => info.nudgeIndex !== -1),
      }))
      .find((info) => info.behaviorInfo);

    if (nudgeInfo) {
      setSelectedStakeholderIndex(nudgeInfo.stakeholderIndex);

      setTimeout(() => {
        const el =
          nudgeRefs.current[
            `${nudgeInfo.stakeholderIndex}-${nudgeInfo.behaviorInfo.behaviorIndex}-${nudgeInfo.behaviorInfo.nudgeIndex}`
          ];
        el.scrollIntoView({ behavior: "smooth" });
        highlightElement(el);
      }, 0);
      return;
    }

    // Check if it's a Sprinter
    const sprinterInfo = data.children
      .map((stakeholder, stakeholderIndex) => ({
        stakeholderIndex,
        behaviorInfo: stakeholder.children
          ?.map((behavior, behaviorIndex) => ({
            behaviorIndex,
            nudgeInfo: behavior.children
              ?.map((nudge, nudgeIndex) => ({
                nudgeIndex,
                sprinterIndex: nudge.children?.findIndex(
                  (sprinter) => sprinter.id === id
                ),
              }))
              .find((info) => info.sprinterIndex !== -1),
          }))
          .find((info) => info.nudgeInfo),
      }))
      .find((info) => info.behaviorInfo);

    if (sprinterInfo) {
      setSelectedStakeholderIndex(sprinterInfo.stakeholderIndex);

      setTimeout(() => {
        const el =
          sprinterRefs.current[
            `${sprinterInfo.stakeholderIndex}-${sprinterInfo.behaviorInfo.behaviorIndex}-${sprinterInfo.behaviorInfo.nudgeInfo.nudgeIndex}`
          ];
        el.scrollIntoView({ behavior: "smooth" });
        highlightElement(el);
      }, 0);
      return;
    }

    const sprint = sprints.find((sprint) => sprint.id === id);
    if (sprint) {
      handleSprintClick(sprint.id, sprint); // Navigate to the sprint
      return;
    }
  };

  const handleSprintClick = (sprintId, sprint) => {
    const today = new Date();
    const startDate = new Date(sprint.startDate);
    setSelectedStakeholderIndex(null);

    if (startDate <= today) {
      // If sprint has started, show SprintCalendar

      setActiveSprint({
        sprintId: sprint.id,
        sprintData: sprint,
        problemId: data.id,
        fromProblem: false,
      });
    } else {
      // If sprint hasn't started, show TaskMonitor

      setActiveSprint({
        sprintId,

        stakeholderId: sprint.stakeholderid,

        data,
        behaviorId: sprint.behaviorId,

        nudgeId: sprint.nudgeId,

        fromProblem: true,
        sprintDetails: sprint,
      });
    }
  };

  const addSprint = (stakeholderIndex, behaviorIndex, nudgeIndex) => {
    if (!data || !data.children || !data.children[stakeholderIndex]) {
      console.error("Selected stakeholder does not exist.");
      return;
    }

    const selectedStakeholder = data.children[stakeholderIndex];
    const selectedBehavior = selectedStakeholder.children[behaviorIndex];
    const selectedNudge = selectedBehavior.children[nudgeIndex];

    setActiveSprint({
      sprintId: null,

      stakeholderId: selectedStakeholder.id,
      behaviorId: selectedBehavior.id,
      nudgeId: selectedNudge.id,
      data,
      sprintDetails: null,
      fromProblem: true,
    });
  };

  const hasNullValues = (node = data, depth = 0) => {
    if (!node || !node.name) return true; // Check if node or its name is null
    if (depth >= 4) return false; // Stop checking after three levels
    if (node.children) {
      return (
        node.children.some((child) => hasNullValues(child, depth + 1)) ||
        node.children.length === 0
      );
    }
    return false;
  };

  const handleInviteUser = async (
    email,
    stakeholderIndex,
    behaviorId,
    nudgeId,
    sprinterId
  ) => {
    const SignupUsername = email.split("@")[0];
    localStorage.setItem("creatingNewUser", "true");
    try {
      const { data } = await createUser({
        variables: { email, username: SignupUsername },
      });
      await createUserWithEmailAndPassword(auth, email, "agilenudgeplus");

      await sendPasswordResetEmail(auth, email);
      await signInWithEmailAndPassword(
        auth,
        localStorage.getItem("email"),
        yourPassword
      );
      showToast("User invited successfully!", "success");
      refetchUsers(); // Refresh the users list
      setInviteModalOpen(false);
      localStorage.setItem("creatingNewUser", "false");
      // Set the newly invited user as the selected sprinter and call handleEdit
      updateSelectedSprinter(
        stakeholderIndex,
        behaviorId,
        nudgeId,
        sprinterId,
        {
          username: SignupUsername,
          email,
          id: data.createUser.id,
        }
      );
    } catch (error) {
      console.error("Error inviting user:", error);
      showToast(
        "Failed to invite user. User may already exist or the email is invalid.",
        "error"
      );
      localStorage.setItem("creatingNewUser", "false");
    }
  };

  const updateSelectedSprinter = (
    stakeholderIndex,
    behaviorId,
    nudgeId,
    sprinterId,
    newValue
  ) => {
    setSelectedSprinters((prev) => ({
      ...prev,
      [`${stakeholderIndex}-${behaviorId}-${nudgeId}`]: newValue,
    }));

    // Call handleEdit with the new selected user's details
    handleEdit(
      "SPRINTER",
      data.children[stakeholderIndex].id,
      behaviorId,
      nudgeId,
      sprinterId,
      newValue,
      false
    );
  };

  if (userLoading) return <h1>Loading</h1>;
  return (
    <div className="w-screen h-screen font-roboto bg-[#fdf8f8] flex flex-row text-base">
      <div style={{ width: open ? "22%" : "60px" }}>
        {/* Pass open and setOpen to Sidebar */}
        <Sidebar open={open} setOpen={setOpen} />
      </div>
      {showDialog && <NewProblemAlert onClose={handleDialogClose} />}
      <div
        className="flex flex-row items-center"
        style={{ width: contentWidth }}
      >
        <ToastAlert />

        <div style={{ width: `${leftWidth}%` }} className=" h-full">
          {/* <h1 className="w-full text-center mt-2 text-xl text-gray-400">{data?.name}</h1> */}
          <TreeDiagram
            data={data}
            sprints={sprints}
            isZoomEnabled={true}
            addChild={addChild}
            handleNodeClick={handleNodeClick}
            open={open}
          />
        </div>
        <StyledTooltip title={`Drag to expand div`}>
          <div
            onMouseDown={handleMouseDown}
            style={{
              width: "5px",
              cursor: "ew-resize",
              backgroundColor: "gray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              onMouseDown={handleMouseDown}
              className="icon"
              viewBox="0 0 16 16"
              fill="#FFFFFF"
              width="40" // Set a larger width here
              height="40"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
            </svg>
          </div>
        </StyledTooltip>
        <div
          style={{ width: `calc(100% - ${leftWidth}%)` }}
          className="w-[50%] flex rounded-[15px] flex-col h-screen bg-img"
        >
          <div className="w-full h-[70px] bg-primary flex justify-end px-4 items-center">
            <IconButton onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>

            <Menu
              anchorEl={menuAnchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  backgroundColor: "#F3EDF7", // Set background color to #F3EDF7
                },
              }}
            >
              <StyledMenuItem
                onClick={() =>
                  handleDeleteClick(
                    isSprintMonitor && selectedStakeholderIndex === null
                      ? "sprint"
                      : "problem"
                  )
                }
              >
                <DeleteIcon className="mr-2" />
                {isSprintMonitor && selectedStakeholderIndex === null
                  ? "Delete this Sprint"
                  : "Delete this problem statement"}
              </StyledMenuItem>
              <NavLink to="/print" state={{ problem: data, sprints }}>
                <StyledMenuItem onClick={handleMenuClose}>
                  {" "}
                  <Print className=" ml-1 mr-3" /> Print MSOP
                </StyledMenuItem>
              </NavLink>
            </Menu>
          </div>

          <div className="w-full h-[90%]">
            <div className="h-full px-5 py-3">
              <Dialog
                open={inviteModalOpen.isOpen}
                onClose={() =>
                  setInviteModalOpen({
                    isOpen: false,
                  })
                }
                PaperProps={{
                  style: {
                    borderRadius: 28,
                    background: "#ece6f0",
                    width: "560px",
                    height: "290px",
                  },
                }}
              >
                <DialogTitle>Enter the Email ID to invite</DialogTitle>
                <DialogContent>
                  <input
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    placeholder="Enter email"
                    className="w-[100%] h-[57px] bg-white rounded-[10px] border border-[#4f378b] p-2"
                  />
                  <h1 className="font-medium py-2">
                    {" "}
                    Enter your current password to confirm:
                  </h1>
                  <input
                    type="password"
                    value={yourPassword}
                    onChange={(e) => setYourPassword(e.target.value)}
                    placeholder="Enter your password"
                    className="w-[100%] h-[57px] bg-white rounded-[10px] border border-[#4f378b] p-2"
                  />
                  <div className="flex justify-end mt-4 space-x-2">
                    <button
                      onClick={() =>
                        setInviteModalOpen({
                          isOpen: false,
                        })
                      }
                      className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
                    >
                      Cancel
                    </button>
                    <button
                      className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
                      onClick={() =>
                        handleInviteUser(
                          newEmail,
                          inviteModalOpen.stakeholderIndex,
                          inviteModalOpen.behaviorId,
                          inviteModalOpen.nudgeId,
                          inviteModalOpen.sprinterId
                        )
                      }
                    >
                      Send Invite
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                  style: {
                    borderRadius: 28,
                    background: "#ece6f0",
                    width: "560px",
                    height: "150px",
                  },
                }}
              >
                <DialogTitle>
                  Are you sure you want to delete this {deleteType}?
                </DialogTitle>
                <DialogContent>
                  <div className="flex space-x-3 justify-end mt-4">
                    <button
                      className=" h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
                      onClick={() => setDeleteDialogOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className=" h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
                      onClick={confirmDelete}
                    >
                      Confirm
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
              {selectedStakeholderIndex === null && (
                <div className=" h-[100%]  ">
                  {isSprintMonitor && activeSprint.fromProblem ? (
                    <TaskMonitor
                      sprintId={activeSprint.sprintId}
                      sprinter={activeSprint.sprinter}
                      sprintDetails={activeSprint.sprintDetails}
                      stakeholderId={activeSprint.stakeholderId}
                      behaviorId={activeSprint.behaviorId}
                      nudgeId={activeSprint.nudgeId}
                      data={activeSprint.data}
                      setIsSprintMonitor={setIsSprintMonitor}
                    />
                  ) : isSprintMonitor && !activeSprint.fromProblem ? (
                    <SprintCalendar
                      sprintData={activeSprint.sprintData}
                      open={open}
                    />
                  ) : (
                    <div className="text-center mt-10"></div>
                  )}
                </div>
              )}
              {selectedStakeholderIndex !== null && (
                <div className="shadow-md  shadow-gray-400  bg-[#fef7ff] flex flex-col m-4 rounded-[15px] h-[97%] border-[1px] border-gray-300">
                  <div className="flex flex-row w-full mb-4">
                    <div className="w-full flex justify-center items-center px-4">
                      {isStakeholderEdit ? (
                        <div className="w-full space-y-1 flex flex-col justify-center items-center">
                          <input
                            type="text"
                            value={data.children[selectedStakeholderIndex].name}
                            onKeyPress={(e) => {
                              if (e.key === "Enter")
                                handleUpdatedData("stakeholder");
                            }}
                            onChange={(e) =>
                              handleEdit(
                                "STAKEHOLDER",
                                data.children[selectedStakeholderIndex].id,
                                null,
                                null,
                                null,
                                e.target.value
                              )
                            }
                            className="w-[80%] h-[57px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 mt-4"
                          />
                          <h1 className="text-gray-400 text-xs">
                            Press Enter to save
                          </h1>
                        </div>
                      ) : (
                        <div className="font-medium  text-lg  flex flex-col  w-full justify-start pt-4 pl-3 pr-2 ">
                          <div className=" flex flex-row justify-between ">
                            <div className=" flex flex-row justify-start space-x-1">
                              <EditOutlined
                                style={{
                                  width: 20,
                                  height: 20,
                                  fill: "black",
                                  marginTop: "2px",
                                }} // Use fill for SVG colors
                                onClick={() =>
                                  setIsStakeholderEdit(!isStakeholderEdit)
                                }
                              />
                              <h1 className="font-semibold">
                                Stakeholder {selectedStakeholderIndex + 1}
                              </h1>
                            </div>
                            <div className="flex flex-row space-x-2">
                              <DeleteIcon
                                className="ml-2 cursor-pointer mt-2 mr-2"
                                onClick={() =>
                                  handleDeleteClick(
                                    "stakeholder",
                                    data.children[selectedStakeholderIndex].id
                                  )
                                }
                              />
                            </div>
                          </div>
                          <h1 className="  text-sm ml-6">
                            {data.children[selectedStakeholderIndex].name}
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-gray-400 h-[1px] w-full mb-2"></div>
                  {showBehaviorButtons ? (
                    <div className="flex flex-col items-center justify-center w-full">
                      <div className="w-[80%] h-12 flex justify-center items-center">
                        {/* Manual Button */}
                        <div className="grow shrink basis-0 h-12 py-1 justify-center items-center flex">
                          <div
                            className={`grow shrink basis-0 self-stretch rounded-tl-[100px] rounded-bl-[100px] border flex-col justify-center items-center inline-flex ${
                              activeTab === "existing"
                                ? "bg-[#65558f] border-[#65558f]"
                                : "bg-white border-[#65558f]"
                            }`}
                            onClick={() =>
                              handleOpenSearchModal(
                                selectedStakeholderIndex,
                                null
                              )
                            }
                          >
                            <div className="self-stretch grow shrink basis-0 px-3 py-2.5 justify-center items-center gap-2 inline-flex">
                              <button
                                className={`text-center font-medium leading-tight tracking-tight ${
                                  activeTab === "existing"
                                    ? "text-white"
                                    : "text-[#c9c6c5]"
                                }`}
                              >
                                Add behavior from exisiting library
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Existing Button */}
                        <div className="grow shrink basis-0 h-12 py-1 justify-center items-center flex">
                          <div
                            className={`grow shrink basis-0 self-stretch rounded-tr-[100px] rounded-br-[100px] border flex-col justify-center items-center inline-flex ${
                              activeTab === "manual"
                                ? "bg-[#65558f] border-[#65558f]"
                                : "bg-white border-[#65558f]"
                            }`}
                            onClick={() =>
                              handleAddManualBehavior(selectedStakeholderIndex)
                            }
                          >
                            <div className="self-stretch grow shrink basis-0 px-3 py-2.5 justify-center items-center gap-2 inline-flex">
                              <button
                                className={`text-center  font-medium leading-tight tracking-tight ${
                                  activeTab === "manual"
                                    ? "text-white"
                                    : "text-[#c9c6c5]"
                                }`}
                              >
                                Enter behavior manually
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-[90%] flex justify-center"></div>
                  )}

                  <div className="overflow-y-scroll scrollbar h-[100%] ">
                    <div className="flex flex-col  pr-5 w-full">
                      {!showBehaviorButtons &&
                        data.children[selectedStakeholderIndex].children.map(
                          (behavior, behaviorIndex) => (
                            <div
                              key={behavior.id}
                              className="w-full   pb-4 pt-3"
                            >
                              <div className="flex flex-col space-y-2 w-full ">
                                <div className="flex flex-row mb-2 w-[99%] ml-8">
                                  <h1 className="font-medium bg-[#493971] h-[57px] rounded-l-[5px] w-[30%] flex justify-start items-center pl-2 text-white border border-[#4f378b]">
                                    Problem Behavior {behaviorIndex + 1}
                                  </h1>
                                  <input
                                    type="text"
                                    value={behavior.name}
                                    placeholder={`Behavior ${
                                      behaviorIndex + 1
                                    }`}
                                    ref={(el) =>
                                      (behaviorRefs.current[
                                        `${selectedStakeholderIndex}-${behaviorIndex}`
                                      ] = el)
                                    }
                                    onChange={(e) =>
                                      handleEdit(
                                        "BEHAVIOR",
                                        data.children[selectedStakeholderIndex]
                                          .id,
                                        behavior.id,
                                        null,
                                        null,
                                        e.target.value
                                      )
                                    }
                                    className="w-[60%] h-[57px] bg-white rounded-r-[5px] border border-[#4f378b] p-2"
                                  />
                                  <DeleteIcon
                                    className="ml-2 cursor-pointer mt-4 ml-4"
                                    onClick={() =>
                                      handleDeleteClick(
                                        "behavior",
                                        data.children[selectedStakeholderIndex]
                                          .id,
                                        behavior.id
                                      )
                                    }
                                  />
                                </div>
                                <div className="flex flex-row mb-2 w-[89%] ml-8">
                                  <h1 className="font-medium bg-[#493971] rounded-l-[5px] text-white h-[57px] w-[50%] flex justify-start items-center pl-2 border border-[#4f378b]">
                                    Target Behavior {behaviorIndex + 1}
                                  </h1>
                                  <input
                                    type="text"
                                    value={behavior.target}
                                    placeholder={`Behavior ${
                                      behaviorIndex + 1
                                    }`}
                                    onChange={(e) =>
                                      handleEdit(
                                        "BEHAVIOR",
                                        data.children[selectedStakeholderIndex]
                                          .id,
                                        behavior.id,
                                        "target",
                                        null,
                                        e.target.value
                                      )
                                    }
                                    className="w-full h-[57px] bg-white rounded-r-[5px] border border-[#4f378b] p-2"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col w-full ml-4">
                                <div className="mb-4 flex flex-row w-[97%] mt-3 ">
                                  <div className="flex flex-col gap-4 w-full">
                                  {behavior.children.map((nudge, nudgeIndex) => {
  // Check if a sprint has already started for this nudge
  const sprintStartedForNudge = sprints.some(
    (sprint) => sprint.nudgeId === nudge.id && new Date(sprint.startDate) <= new Date()
  );

  return (
    <div
      key={nudge.id}
      className={`rounded w-full flex flex-row space-x-5 pl-4 justify-center `} // Add styles for non-editable nudges
    >
      {/* Left Column - Nudge Type */}
      <div className="flex items-center w-[50%]">
        <h1 className="font-medium bg-[#6750a4] rounded-l-[5px] h-[42px] w-[40%] flex justify-start items-center pl-2 border-[1px] border-black text-white">
          Nudge Type {nudgeIndex + 1}
        </h1>
        <select
          value={nudge.name}
          onChange={(e) =>
            handleEdit(
              "NUDGE",
              data.children[selectedStakeholderIndex].id,
              behavior.id,
              nudge.id,
              null,
              e.target.value
            )
          }
          className="w-[50%] h-[42px] bg-white rounded-r-[5px] border border-[#4f378b] p-2"
          ref={(el) =>
            (nudgeRefs.current[`${selectedStakeholderIndex}-${behaviorIndex}-${nudgeIndex}`] = el)
          }
          disabled={sprintStartedForNudge} // Disable select if sprint has started
        >
          <option value="" disabled hidden>
            Select Nudge Type {nudgeIndex + 1}
          </option>
          {nudgeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {!sprintStartedForNudge && (
          <DeleteIcon
            className="ml-1 cursor-pointer"
            onClick={() =>
              handleDeleteClick(
                "nudge",
                data.children[selectedStakeholderIndex].id,
                behavior.id,
                nudge.id
              )
            }
          />
        )}
      </div>

      {/* Right Column - Sprinter */}
      <div className="w-[50%] flex flex-col space-y-2">
        <div className="flex flex-row justify-center">
          <div className="flex items-center w-full">
          <StyledTooltip title = "Type in the name of the person responsible for carrying out this sprint. This can be yourself or a team member. If this account is part of a group, you can select group members from a drop down that will appear when you click in the box. If you would like to invite an external user to join, you can click the email icon to send an invite. *If the email already exists in AgileNudgePlus, then an error message will appear on the screen. Close the alert and type in the sprinter name.">
            <h1 className="font-medium bg-[#6750a4] rounded-l-[5px] h-[42px] w-[30%] flex justify-start items-center pl-2 border-[1px] border-black text-white">
              Sprinter 
            </h1>
            </StyledTooltip>
            <div key={nudge.id} className="flex items-center space-x-2 w-[70%]">
              <Autocomplete
                PopperComponent={CustomPopper}
                options={usersData.allUsers}
                PaperComponent={CustomPaper}
                freeSolo
                disableClearable
                sx={{ width: "100%" }}
                ref={(el) =>
                  (sprinterRefs.current[
                    `${selectedStakeholderIndex}-${behaviorIndex}-${nudgeIndex}`
                  ] = el)
                }
                value={
                  selectedSprinters[
                    `${selectedStakeholderIndex}-${behavior.id}-${nudge.id}`
                  ] || null
                }
                onChange={(e, newValue) =>
                  updateSelectedSprinter(
                    selectedStakeholderIndex,
                    behavior.id,
                    nudge.id,
                    nudge.children[0]?.id,
                    newValue
                  )
                }
                disabled={sprintStartedForNudge} // Disable Autocomplete if sprint has started
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.username
                }
                filterOptions={(options, params) => {
                  const filtered = options.filter((option) =>
                    option.username
                      .toLowerCase()
                      .includes(params.inputValue.toLowerCase())
                  );
                  return filtered;
                }}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.id}>
                    <img
                      src={option.profilepic || "/default-avatar.png"}
                      alt="Profile"
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    {option.username}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: 42,
                        borderRadius: "0 8px 8px 0",
                      },
                      startAdornment: (
                        <div className="flex items-center space-x-2 pl-2">
                          {selectedSprinters[
                            `${selectedStakeholderIndex}-${behavior.id}-${nudge.id}`
                          ] && (
                            <>
                              <img
                                src={
                                  selectedSprinters[
                                    `${selectedStakeholderIndex}-${behavior.id}-${nudge.id}`
                                  ].profilepic || "/default-avatar.png"
                                }
                                alt="Selected Profile"
                                className="w-6 h-6 rounded-full"
                              />
                            </>
                          )}
                        </div>
                      ),
                    }}
                  />
                )}
              />
              {/* Invite Button */}
              {!sprintStartedForNudge && (
                <button
                  onClick={() =>
                    setInviteModalOpen({
                      isOpen: true,
                      stakeholderIndex: selectedStakeholderIndex,
                      behaviorId: behavior.id,
                      nudgeId: nudge.id,
                      sprinterId: nudge.children[0]?.id,
                    })
                  }
                >
                  <Mail className="ml-1" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}

                      {isSearchModalOpen && (
                        <div className="h-full">
                          <SearchableDropdown
                            onClose={handleCloseSearchModal}
                            addBehavior={addBehavior}
                            stakeholder={currentStakeholder}
                            addNudge={addNudge}
                            behaviorRefs={behaviorRefs}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full flex justify-end px-5 py-4">
                    <button
                      className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
                      onClick={() => handleUpdatedData(true)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeView;
