import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Collapse as MuiCollapse,
  Switch,
  styled,
} from "@mui/material";
import StyledTooltip from "./StyledTooltip";
import { ReactComponent as Archives } from "../../Assets/Svgs/archives.svg";
import { ReactComponent as Delete } from "../../Assets/Svgs/delete.svg";
import { ReactComponent as SuccessfulNudges } from "../../Assets/Svgs/successfulnudges.svg";
import { ReactComponent as Radio } from "../../Assets/Svgs/radio.svg";

import {
  ExpandLess,
  ExpandMore,
  AddCircleOutline as AddCircleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Error as ErrorIcon,
  Logout,
} from "@mui/icons-material";

import Edit from "@mui/icons-material/EditOutlined";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useQuery } from "@apollo/client";
import { GET_ALL_PROBLEMS, GET_ALL_SPRINTS } from "../../Graphql/User/Query";
import logo from "../../Assets/Images/anplogo.png";

import { NavLink } from "react-router-dom";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 58,
  height: 32,
  padding: 0,
  display: "flex",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#65558F",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65558F",
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiSwitch-thumb": {
    padding: 13,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage:
      "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTYuMzY2NDEgMTIuMDAxTDIuNTY2NDEgOC4yMDEwNEwzLjUxNjQxIDcuMjUxMDRMNi4zNjY0MSAxMC4xMDFMMTIuNDgzMSAzLjk4NDM4TDEzLjQzMzEgNC45MzQzN0w2LjM2NjQxIDEyLjAwMVoiIGZpbGw9IiM0RjM3OEEiLz4KPC9zdmc+Cg==')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "16px 16px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    backgroundColor: "#65558F",
    opacity: 1,
  },
}));
const Sidebar = ({ open, setOpen }) => {
  const [openMenus, setOpenMenus] = useState({});
  const [userSprintsGrouped, setUserSprintsGrouped] = useState({});
  useEffect(() => {
    // Reset selected menu on component load
    setSelectedMenu(null);
    localStorage.removeItem("selectedMenu");
    localStorage.removeItem("openMenus");
  }, []);

  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(
    localStorage.getItem("selectedMenu")
  );
  const navigate = useNavigate();
  const [view, setView] = useState("Admin");
  const handleViewToggle = () => setView(view === "Admin" ? "Sprint" : "Admin");

  const {
    data: problemData,
    loading,
    error,
  } = useQuery(GET_ALL_PROBLEMS, {
    variables: { userId: localStorage.getItem("userId") },
  });

  const { data: sprintData } = useQuery(GET_ALL_SPRINTS);
  console.log(sprintData);
  useEffect(() => {
    const savedOpenMenus = JSON.parse(localStorage.getItem("openMenus")) || {};
    setOpenMenus(savedOpenMenus);
  }, []);
  useEffect(() => {
    const getUserSprintsGroupedByProblem = () => {
      const userId = localStorage.getItem("userId");

      if (!userId || !sprintData || !sprintData.getAllSprints) return {};

      // Filter by user ID, then group by problemId with problemName as the key
      const groupedSprints = sprintData.getAllSprints
        .filter((sprint) => sprint.sprinterId === userId)
        .reduce((groupedSprints, sprint) => {
          const { problemId, problemName } = sprint;

          // Use problemName as the key for the group
          if (!groupedSprints[problemName]) {
            groupedSprints[problemName] = { problemId, sprints: [] };
          }

          groupedSprints[problemName].sprints.push(sprint);
          return groupedSprints;
        }, {}); // Initial value as an empty object

      console.log(groupedSprints);
      return groupedSprints;
    };

    // Set the result of the grouping function to state
    setUserSprintsGrouped(getUserSprintsGroupedByProblem());
  }, [sprintData]);
  const toggleSidebar = () => {
    const newSidebarState = !open;
    setOpen(newSidebarState);
    localStorage.setItem("sidebarOpen", newSidebarState);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const toggleMenu = (menuId, problemId, sprint = null) => {
    const updatedMenus = {
      ...openMenus,
      [menuId]: !openMenus[menuId],
    };
    setOpenMenus(updatedMenus);
    localStorage.setItem("openMenus", JSON.stringify(updatedMenus));
    console.log(problemId);
    // Only pass `sprintfromsidebar` when it’s a sprint click
    if (sprint) {
      navigate("/nudgebuilder/custombuilder", {
        state: { sprintfromsidebar: sprint, problemId },
      });
    } else {
      navigate("/nudgebuilder/custombuilder", {
        state: { sprintfromsidebar: null, problemId },
      });
    }
  };
  const getUniqueSprinters = (problem) => {
    const sprinters = [];
    problem.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          nudge.children.forEach((sprinter) => {
            if (!sprinters.find((s) => s.sprinterId === sprinter.sprinterId)) {
              sprinters.push(sprinter);
            }
          });
        });
      });
    });
    return sprinters;
  };
  const handleProfileClick = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileMenuAnchor(null);
  };

  const handleEditProfile = () => {
    navigate("/profile");
    handleCloseProfileMenu();
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "In Progress":
        return "#FFBD2E";
      case "Success":
        return "#8BC34A";
      case "Terminated":
        return "#B3261E";
      default:
        return "#FFBD2E";
    }
  };

  const getSprintsBySprinter = (problemId, sprinterName) => {
    return sprintData?.getAllSprints.filter(
      (sprint) =>
        sprint.problemId === problemId && sprint.sprinterName === sprinterName
    );
  };

  const calculateCompletion = (problem) => {
    let totalNudgeTypes = 0;
    let nudgeTypesWithSprints = 0;
  
    // Traverse through the nested structure
    problem.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          totalNudgeTypes += 1;
  
          // Check if this nudge type has any associated sprint
          const hasSprint = sprintData?.getAllSprints.some(
            (sprint) =>
              sprint.problemId === problem.id && sprint.nudgeId === nudge.id
          );
  
          if (hasSprint) {
            nudgeTypesWithSprints += 1;
          }
        });
      });
    });
  
    // Calculate the completion percentage
    return totalNudgeTypes === 0
      ? 0
      : (nudgeTypesWithSprints / totalNudgeTypes) * 100;
  };

  // Helper function to calculate sprint progress
  const calculateSprintProgress = (sprint) => {
    const today = new Date();
    const startDate = new Date(sprint.startDate);
    const endDate = new Date(sprint.endDate);

    if (today < startDate) return 0; // Sprint hasn't started
    if (today > endDate) return 100; // Sprint has ended

    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const elapsedDays = (today - startDate) / (1000 * 60 * 60 * 24);

    return Math.min((elapsedDays / totalDays) * 100, 100); // Ensure it doesn't exceed 100
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div
      className={`font-roboto text-base ${
        profileMenuAnchor ? "blur-background" : ""
      }`}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: open ? "20%" : "90px",
          transition: "width 0.3s",
          "& .MuiDrawer-paper": {
            width: open ? "20%" : "90px",
            overflowX: "hidden",
            transition: "width 0.3s",
          },
        }}
      >
        {/* Top Section */}
        <div className="flex flex-row justify-between items-center bg-primary p-4">
          <img src={logo} className="w-[40px] h-[36px]" alt="Logo" />
          <StyledTooltip title={`Expand-Collapse Sidebar`}>
            <IconButton onClick={toggleSidebar}>
              {open ? (
                <ChevronLeftIcon className="text-white" />
              ) : (
                <ChevronRightIcon className="text-white" />
              )}
            </IconButton>
          </StyledTooltip>
        </div>

        {/* Profile Section */}
        {open ? (
          <div className="w-full flex justify-center items-center -mt-7">
            <StyledTooltip title={`Profile Settings`}>
              <img
                src={localStorage.getItem("profilepic")}
                className={`w-[58px] h-[58px] rounded-full shadow p-[1px] bg-white cursor-pointer ${
                  profileMenuAnchor
                    ? "border-4 border-[#6750A4]"
                    : "border-2 border-white"
                }`}
                alt="Profile"
                onClick={handleProfileClick}
              />
            </StyledTooltip>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center mt-1">
            <StyledTooltip title={`Profile Settings`}>
              <img
                src={localStorage.getItem("profilepic")}
                className={`w-[50px] h-[50px] rounded-full shadow p-[1px] bg-white cursor-pointer ${
                  profileMenuAnchor
                    ? "border-4 border-[#6750A4]"
                    : "border-2 border-white"
                }`}
                alt="Profile"
                onClick={handleProfileClick}
              />
            </StyledTooltip>
          </div>
        )}

        {/* Profile Menu */}
        <Menu
          anchorEl={profileMenuAnchor}
          open={Boolean(profileMenuAnchor)}
          onClose={handleCloseProfileMenu}
          PaperProps={{
            style: {
              backgroundColor: "#F3EDF7", // Set background color to #F3EDF7
            },
          }}
        >
          <MenuItem onClick={handleEditProfile} style={{ fontSize: "16px" }}>
            <Edit className="mr-2" /> Edit Profile
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ fontSize: "16px" }}>
            <Logout className="mr-2" /> Logout
          </MenuItem>
        </Menu>

        {/* Main Action Button */}
        <div className="px-3 w-full flex flex-col space-y-5">
          {open ? (
            <NavLink to="/">
              <button className="w-full h-14 bg-[#e8def8] rounded-full flex items-center justify-center px-4 text-[#4a4459] font-semibold mt-5">
                <AddCircleIcon className="mr-2" />{" "}
                <StyledTooltip title={`Generate Problem Statement`}>
                  {" "}
                  Generate Problem Statement
                </StyledTooltip>
              </button>
            </NavLink>
          ) : (
            <NavLink to="/">
              <div className="bg-[#e8def8] p-1 rounded-full mt-2 w-[65px] h-[56px] flex justify-center items-center">
                <StyledTooltip title={`Generate Problem Statement`}>
                  {" "}
                  <AddCircleIcon />{" "}
                </StyledTooltip>
              </div>
            </NavLink>
          )}

          {/* <div
            className={`flex flex-row space-x-4 px-2 ${
              open ? "justify-start" : "justify-center"
            } items-center ${
              selectedMenu === "success"
                ? "bg-bcolor p-2 rounded-lg text-white"
                : "text-black"
            }`}
            onClick={() => setSelectedMenu("success")}
          >
            <StyledTooltip title={`Successful NUdges`}>
              <SuccessfulNudges
                fill={selectedMenu === "success" ? "white" : "currentColor"}
              />{" "}
         
            </StyledTooltip>
            {open && <h1 className="font-medium">Succeessful Nudges</h1>}
          </div>
          <div
            className={`flex flex-row space-x-4 px-2 ${
              open ? "justify-start" : "justify-center"
            } items-center ${
              selectedMenu === "Archives"
                ? "bg-bcolor p-2 rounded-lg text-white"
                : "text-black"
            }`}
            onClick={() => setSelectedMenu("Archives")}
          >
            <StyledTooltip title={`Archives`}>
              <Archives
                fill={selectedMenu === "Archives" ? "white" : "currentColor"}
              />{" "}
       
            </StyledTooltip>
            {open && <h1 className="font-medium">Archives</h1>}
          </div>

          <div
            className={`flex flex-row space-x-4 px-2 ${
              open ? "justify-start" : "justify-center"
            } items-center ${
              selectedMenu === "trash"
                ? "bg-bcolor p-2 rounded-lg text-white"
                : "text-black"
            }`}
            onClick={() => setSelectedMenu("trash")}
          >
            <StyledTooltip title={`Trash`}>
              <Delete
                fill={selectedMenu === "trash" ? "white" : "currentColor"}
              />{" "}
     
            </StyledTooltip>
            {open && <h1 className="font-medium">Trash</h1>}
          </div> */}

          <div className="pb-3">
            {open ? (
              <div className="flex flex-row justify-center w-full items-center px-2 space-x-2">
                <h3
                  className={`text-base  font-semibold ${
                    view === "Admin"
                      ? "text-[#65558F] "
                      : "text-[#79747E80] text-opacity-50"
                  } m-0`}
                >
                  Admin View
                </h3>
                <CustomSwitch
                  checked={view === "Sprint"}
                  onChange={handleViewToggle}
                />
                <h3
                  className={`text-base font-semibold  ${
                    view === "Sprint"
                      ? "text-[#65558F] "
                      : "text-[#79747E80] text-opacity-50"
                  } m-0`}
                >
                  Sprinter View
                </h3>
              </div>
            ) : (
              <div className="text-bcolor  flex flex-row space-x-2">
                <h1>{view}</h1> <Radio />
              </div>
            )}
          </div>
        </div>
        {view === "Admin" ? (
          <List
            component="nav"
            className="overflow-y-auto h-[57%]  flex flex-col space-y-1 mt-4   "
          >
            {problemData.getAllProblems
              .slice() // create a shallow copy to avoid mutating the original data
              .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
              .map((problem) => {
                const completionPercentage = calculateCompletion(problem);
                const uniqueSprinters = getUniqueSprinters(problem);

                return (
                  <div
                    key={problem.id}
                    className="flex flex-col border-b-[1px] border-[#cac4d0] w-full "
                  >
                    <StyledTooltip
                      title={`${problem.name}\n${completionPercentage.toFixed(
                        2
                      )}% complete`}
                    >
                      <ListItem
                        onClick={() => {
                          setSelectedMenu(`problem-${problem.id}`);
                          toggleMenu(`problem-${problem.id}`, problem.id);
                        }}
                        className={`flex space-x-2 items-center cursor-pointer ${
                          selectedMenu === `problem-${problem.id}`
                            ? "bg-bcolor rounded-lg text-white"
                            : "text-[#4a4459]"
                        } ${open ? "justify-start" : "justify-center"}`} // Conditional alignment
                      >
                        {/* Conditionally render Expand/Collapse icon */}
                        {open &&
                          (openMenus[`problem-${problem.id}`] ? (
                            <ExpandLess />
                          ) : (
                            <ChevronRightIcon />
                          ))}

                        {/* Problem Name */}
                        {open && (
                          <ListItemText
                            primary={problem.name.substring(0, 20) + "..."}
                          />
                        )}

                        {/* CircularProgress centered when sidebar is closed */}
                        <div
                          className={`${
                            open ? "" : "flex justify-center w-full"
                          }`}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={completionPercentage}
                            size={30}
                            thickness={5}
                            style={{
                              color:
                                selectedMenu === `problem-${problem.id}`
                                  ? "#ffffff"
                                  : "#65558f",
                            }}
                          />
                        </div>
                      </ListItem>
                    </StyledTooltip>
                    {open && (
                      <MuiCollapse
                        in={openMenus[`problem-${problem.id}`]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {uniqueSprinters.map((sprinter) => (
                            <div key={sprinter.id} className="pl-4 ">
                              <StyledTooltip
                                title={`Sprinter : ${sprinter.name}`}
                              >
                                <ListItem
                                  button
                                  className="border-t-[1px] border-[#cac4d0]"
                                  onClick={() =>
                                    toggleMenu(`sprinter-${sprinter.id}`)
                                  }
                                >
                                  {openMenus[`sprinter-${sprinter.id}`] ? (
                                    <ExpandLess />
                                  ) : (
                                    <ChevronRightIcon />
                                  )}
                                  <ListItemText
                                    primary={sprinter.name + "'s sprints"}
                                  />
                                </ListItem>
                              </StyledTooltip>
                              <MuiCollapse
                                in={openMenus[`sprinter-${sprinter.id}`]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {getSprintsBySprinter(
                                    problem.id,
                                    sprinter.name
                                  )?.map((sprint) => (
                                    <StyledTooltip
                                      title={`Sprint: ${sprint.name}`}
                                    >
                                      <ListItem
                                        key={sprint.id}
                                        button
                                        onClick={() =>
                                          toggleMenu(
                                            `sprint-${sprint.id}`,
                                            problem.id,
                                            sprint
                                          )
                                        }
                                        className="flex items-center justify-between px-3 py-2"
                                      >
                                        <div className="flex justify-between items-center space-x-2 w-full">
                                          <CircularProgress
                                            variant="determinate"
                                            value={calculateSprintProgress(
                                              sprint
                                            )}
                                            size={30}
                                            thickness={5}
                                            style={{
                                              color: getStatusColor(
                                                sprint.status
                                              ),
                                            }}
                                          />
                                          <div
                                            className={`ml-2 pl-5 w-[80%] h-[33px] pt-1 rounded-full bg-[${getStatusColor(
                                              sprint.status
                                            )}] text-white `}
                                          >
                                            {sprint.name}
                                          </div>
                                        </div>
                                        <div>
                                          {/* Circular progress for the sprint */}
                                        </div>
                                      </ListItem>
                                    </StyledTooltip>
                                  ))}
                                </List>
                              </MuiCollapse>
                            </div>
                          ))}
                        </List>
                      </MuiCollapse>
                    )}
                  </div>
                );
              })}
          </List>
        ) : (
          <List
            component="nav"
            className="overflow-y-auto h-[57%] flex flex-col space-y-1 mt-4"
          >
            {Object.keys(userSprintsGrouped).map((problemName) => {
              const sprints = userSprintsGrouped[problemName].sprints;

              // Calculate average progress for the problem
              const totalProgress = sprints.reduce(
                (sum, sprint) => sum + calculateSprintProgress(sprint),
                0
              );
              const averageProgress = totalProgress / sprints.length;

              return (
                <div
                  key={userSprintsGrouped[problemName].problemId}
                  className="flex flex-col border-b-[1px] border-[#cac4d0] w-full"
                >
                  <StyledTooltip
                    title={`${problemName}\n${averageProgress.toFixed(
                      2
                    )}% complete`}
                  >
                    <ListItem
                      onClick={() =>
                        setOpenMenus((prev) => ({
                          ...prev,
                          [problemName]: !prev[problemName],
                        }))
                      }
                      className={`flex space-x-2 items-center cursor-pointer ${
                        openMenus[problemName]
                          ? "bg-bcolor rounded-lg text-white"
                          : "text-[#4a4459]"
                      } ${open ? "justify-start" : "justify-center"}`}
                    >
                      {/* Expand/Collapse Icon */}
                      {open &&
                        (openMenus[problemName] ? (
                          <ExpandLess />
                        ) : (
                          <ChevronRightIcon />
                        ))}

                      {/* Problem Name */}
                      {open && (
                        <ListItemText
                          primary={problemName.substring(0, 20) + "..."}
                        />
                      )}

                      {/* Circular Progress */}
                      <div
                        className={`${
                          open ? "" : "flex justify-center w-full"
                        }`}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={averageProgress}
                          size={30}
                          thickness={5}
                          style={{
                            color: openMenus[problemName]
                              ? "#ffffff"
                              : "#65558f",
                          }}
                        />
                      </div>
                    </ListItem>
                  </StyledTooltip>

                  {open && (
                    <MuiCollapse
                      in={openMenus[problemName]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {sprints.map((sprint) => (
                          <StyledTooltip title={`Sprint: ${sprint.name}`}>
                            <ListItem
                              key={sprint.id}
                              button
                              onClick={() =>
                                toggleMenu(
                                  `sprint-${sprint.id}`,
                                  userSprintsGrouped[problemName].problemId,
                                  sprint
                                )
                              }
                              className="flex items-center justify-between px-3 py-2"
                            >
                              <div className="flex justify-between items-center space-x-2 w-full">
                                <CircularProgress
                                  variant="determinate"
                                  value={calculateSprintProgress(sprint)}
                                  size={30}
                                  thickness={5}
                                  style={{
                                    color: getStatusColor(sprint.status),
                                  }}
                                />
                                <div
                                  className={`ml-2 pl-5 w-[80%] h-[33px] pt-1 rounded-full bg-[${getStatusColor(
                                    sprint.status
                                  )}] text-white`}
                                >
                                  {sprint.name}
                                </div>
                              </div>
                            </ListItem>
                          </StyledTooltip>
                        ))}
                      </List>
                    </MuiCollapse>
                  )}
                </div>
              );
            })}
          </List>
        )}
        <div className="flex-grow" />
        <NavLink to="/tutorial">
          <div
            className={`w-full h-[50px] bg-[#00adb5] flex items-center px-4 space-x-3 ${
              open ? "justify-start" : "justify-center"
            }`}
          >
            <HelpIcon style={{ color: "white" }} />
            {open && (
              <span className="text-white">Tutorials and Resources</span>
            )}
          </div>
        </NavLink>
        <NavLink to="/aboutus">
          <div
            className={`w-full h-[50px] bg-[#00adb5] flex items-center px-4 space-x-3 ${
              open ? "justify-start" : "justify-center"
            }`}
          >
            <ErrorIcon style={{ color: "white" }} />
            {open && <span className="text-white">About Us</span>}
          </div>
        </NavLink>
      </Drawer>
    </div>
  );
};

export default Sidebar;
